import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components, has to do with spacing the background
export const SpacerXSmall = styled.div`
  height: 100px;
  width: 100px;
`;

// Used for providing space between components, top photo and box containing dapp
export const SpacerSmall = styled.div`
  height: 1px;
  width: 1px;
`;

// Used for providing space between components, has to do with spacing the background
export const SpacerMedium = styled.div`
  height: 100px;
  width: 100px;
`;

// Used for providing space between components, idk which
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

// used for font of cost
export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 30px;
  font-weight: 600;
  line-height: 2.5;
`;

// used for idk
export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

// appears to change the zoom of the background
export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 15px;
  line-height: 1.6;
`;

// idk what this changes
export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
