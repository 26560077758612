import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  // formats the connect button
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 200px;
  border: none;
  background-color: var(--button-fill);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary);
  width: 175px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30x;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

// idk what this changes
export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

// alters the border around the two circles with the example.png
export const StyledImg = styled.img`
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.7);
  border: 0px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click MINT to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Success!  You now own ${CONFIG.NFT_NAME}!  Go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  // this is for altering the large box containing mint button, total, etc
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 50, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />


        <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "20px", margin: "25px", 
                  padding: 10,
                  boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
                  backgroundColor: "var(--secondary-text)",
                  borderRadius: 12,}}
                  
                >
                  Welcome to the Pilot Pig Alliance NFT project!  Thanks for checking out the page!  Pilot Pig Alliance is a collection of 7,370 unique NFTs on the Ethereum blockchain.  Each trait has been hand-drawn with over 2.3 million different NFT combinations.  This project is entirely a one-person team and I am extremely excited to share it with you.  Please visit the official Twitter and Discord links below to learn more about the project and to stay updated with the latest news.  Feel free to reach out to me via either of those methods.
            
                </s.TextDescription>
    
      
         
    
       

        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 1,
              borderRadius: 25,
              border: "0px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 34,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
              &nbsp; &nbsp; Already Minted

            </s.TextTitle>
            
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  SOLD OUT
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ 
                    textAlign: "center", 
                    color: "var(--accent-text)", 
                    fontsize: "34"  }}
                  // ABOVE TEXT MODIFIES THE COST TEXT LINE
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                
                <br></br>

                


                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "19px" }}
                  // ABOVE TEXT MODIFES THE "DISPLAYED COST TEXT..." LINE
                >
                  I have not launched the project yet so the OpenSea link, Contract link, and Mint button will not work
            
                </s.TextDescription>
                <br></br>
                
                <s.SpacerSmall />
                
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: "17px"
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
      <br>
      </br>
     
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      style={{ lineHeight: 1.4, fontSize: "17px",}}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                    CONNECT WALLET
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 1 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        style={{ lineHeight: 1,
                               

                        }}

                        
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        style={{ lineHeight: 2.5, fontSize: "17px",}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />
            

          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>

          
        </ResponsiveWrapper>
<br></br>

          <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                fontSize: "18px"
              }}
            >
              <StyledLink target={"_blank"} href="https://twitter.com/PilotPigNFT" target="_blank" rel="noopener noreferrer"
              style={{
                padding: 10,
                boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
                backgroundColor: "var(--accent)",
                borderRadius: 12,
              }}
              >
              
                Twitter
                
              </StyledLink>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <StyledLink target={"_blank"} href="https://discord.com/channels/942269526488268861/942269527062904864" target="_blank" rel="noopener noreferrer"
               style={{
                padding: 10,
                boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
                backgroundColor: "var(--accent)",
                borderRadius: 12,
              }}>
              
              Discord
              
            </StyledLink>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <StyledLink target={"_blank"} href="https://opensea.io" target="_blank" rel="noopener noreferrer"
             style={{
              padding: 10,
              boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
              backgroundColor: "var(--accent)",
              borderRadius: 12,
            }}>
              
              OpenSea
              
            </StyledLink>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK} target="_blank" rel="noopener noreferrer"
             style={{
              padding: 10,
              boxShadow: "0px 5px 11px 5px rgba(0,0,0,0.7)",
              backgroundColor: "var(--accent)",
              borderRadius: 12,
            }}>
              
              Contract
              
            </StyledLink>

            </s.TextDescription>
        
          


        <s.SpacerMedium />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            
          
            
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and using the correct address. 
             Please note:  once you make the purchase, you cannot undo it.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
           
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}


export default App;




